<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        People
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
          @click="addPerson"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Person</span>
      </button>
    </div>

    <div class="flex flex-wrap w-full">
      <DataTable
          v-if="people.length > 0"
          class="w-full"
          :value="people"
          :rows="10"
          :paginator="true"
          :rowsPerPageOptions="[5, 10, 20]"
      >
        <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :sortable="col.sortable"
            :headerStyle="{ width: col.width }"
        >
          <template v-slot:body="slotProps">
            <template v-if="col.slotName === 'is-default-slot'">
              <span
                  class="px-2 rounded-lg"
                  :class="{
                  'bg-yellow-200 text-yellow-700': !slotProps.data.is_default,
                  'bg-green-200 text-green-700': slotProps.data.is_default
                }"
              >
                {{ slotProps.data.is_default ? "Default" : "Not Default" }}
              </span>
            </template>

            <template v-else-if="col.slotName === 'action-slot'">
              <div class="flex space-x-4">
                <AppButton
                    color="blue"
                    label=""
                    :icon="true"
                    @click.native.stop="openEditPerson(slotProps)"
                >
                  <template v-slot:icon>
                    <Icon iconType="edit" />
                  </template>
                </AppButton>
                <AppButton
                    color="red"
                    label=""
                    :icon="true"
                    @click.native.stop="confirmDeletePerson(slotProps)"
                >
                  <template v-slot:icon>
                    <Icon iconType="delete" />
                  </template>
                </AppButton>
              </div>
            </template>

            <template v-else>
              {{ slotProps.data[col.field] || "N/A" }}
            </template>
          </template>
        </Column>

        <template #empty>
          <p class="mx-auto my-16">There are no People on this Contact.</p>
        </template>
      </DataTable>
    </div>

    <portal to="overlay-outlet">
      <panel
          :showing="addEditContactPanelOpen"
          @close="closeAddEditPerson"
          :title="editingContact.contact_id ? 'Edit Contact' : 'Add Contact'"
      >
        <AddPerson
            :contact_id="contact_id"
            :person_id="selectedPersonId"
            :initial_person="selectedPerson"
            @refreshContact="onPersonSaved"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
          :showingConfirm="confirmAlert.showing"
          :message="confirmAlert.message"
          @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";
import notificationMixin from "@/mixins/notificationMixin";

const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddPerson = () => import("@/components/Contacts/AddPerson.vue");

export default {
  mixins: [notificationMixin],
  name: "People",
  components: {
    AppButton,
    Icon,
    Notification,
    ConfirmAlert,
    Panel,
    AddPerson,
  },
  props: {
    people: Array,
    contact_id: String,
  },
  data() {
    return {
      addEditContactPanelOpen: false,
      editingContact: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        { field: "firstname", header: "First Name", sortable: true, width: "15%" },
        { field: "lastname", header: "Last Name", sortable: true, width: "15%" },
        { field: "email", header: "Email", sortable: true, width: "20%" },
        { field: "mobile", header: "Mobile", sortable: true, width: "15%" },
        { field: "telephone", header: "Telephone", sortable: true, width: "15%" },
        {
          field: "is_default",
          header: "",
          width: "10%",
          custom: true,
          slotName: "is-default-slot",
        },
        {
          field: "action",
          header: "Actions",
          sortable: false,
          width: "10%",
          custom: true,
          slotName: "action-slot",
        },
      ],
      selectedPersonId: "",
      selectedPerson: null,
    };
  },
  methods: {
    addPerson() {
      this.editingContact = {};
      this.selectedPersonId = "";
      this.selectedPerson = null;
      this.addEditContactPanelOpen = true;
    },

    async onPersonSaved() {
      this.closeAddEditPerson();
      await this.refreshContact();
    },

    closeAddEditPerson() {
      this.addEditContactPanelOpen = false;
    },

    async refreshContact() {
      this.$emit("refreshContact");
    },
    openEditPerson(selectedRow) {
      debugger;

      if (selectedRow && selectedRow.data && selectedRow.data.person_id) {
        this.selectedPersonId = selectedRow.data.person_id;
        this.selectedPerson = selectedRow.data;
        this.addEditContactPanelOpen = true;
      }
    },
    async confirmDeletePerson(selectedRow) {
      if (!selectedRow || !selectedRow.data || !selectedRow.data.person_id) return;

      const personId = selectedRow.data.person_id;
      const confirmation = await this.confirmAction({
        message: "Do you want to delete this person?",
        header: "Confirm Action",
        acceptLabel: "Yes",
        rejectLabel: "No",
      });

      if (confirmation) {
        try {
          if (personId) {
            await this.ContactService.deletePerson(this.contact_id, personId);
            this.notifySuccess("Person deleted successfully");
            await this.refreshContact();
          } else {
            this.notifyError("Person ID is missing");
          }
        } catch (error) {
          this.notifyError("Couldn't delete the person");
          console.error(error);
        }
      }
    },
  },
};
</script>
